import { json, LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { Link, useLoaderData, useNavigate } from "@remix-run/react";
import { useEffect, useState } from "react";
import { Discord } from "~/components/discord";
import Layout from "~/components/layouts/main";
import PremiumNotify from "~/components/layouts/subscription";
import PartnerSection from "~/components/partners";
import { getUser, requireUserId } from "~/lib/auth.server";
import config from "~/lib/configs/config.json";
import { prisma } from "~/lib/prisma.server";


export async function loader({ request }: LoaderFunctionArgs) {
    const user = await getUser(request);
    if (!user) {
      return json({ user: null, userDetails: null });
    }
    try {
      const userId = await requireUserId(request);
      const userDetails = await prisma.user.findUnique({
        where: { id: userId },
        select: {
          profileCard: true,
          subscription:true,
          links: true
        },
      });

      if (!userDetails) {
        throw new Error('User details not found');
      }

      return json({ user, userDetails });

    } catch (error) {
      console.error('Loader error:', error);
      throw new Response("Error loading user data", { status: 500 });
    }
  }
const HomePage: React.FC = () => {
    const [username, setUsername] = useState<string>("");
    const { user,userDetails } = useLoaderData<typeof loader>();
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        setIsLoaded(true);
      }, []);
  return (
    <Layout>
      <section className="relative flex flex-col items-center justify-center min-h-screen overflow-hidden p-6 mt-20 pb-32 z-10">
        <img
          src="/Graphics/bg.png"
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover opacity-10"
        />
        <div className="max-w-7xl mx-auto w-full">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div className={`space-y-8 transition-all duration-1000 ${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4'}`}>
              <a
                href={config.Discord}
                className="group inline-flex items-center gap-2 rounded-full border border-white/20 bg-primary/10 px-5 py-2 text-primary transition-colors duration-300 hover:bg-primary/20 animate-fadeIn relative overflow-hidden text-xs"
              >
                <span className="absolute right-0 -mt-12 h-32 w-8 translate-x-12 rotate-12 bg-white opacity-10 transition-all duration-[1.5s] ease-out group-hover:-translate-x-96"></span>
                <i className="fab fa-discord"></i>
                <span>Join our Discord for updates & events</span>
                <i className="fas fa-chevron-right transition-transform group-hover:translate-x-1"></i>
              </a>

              <div>
                <h1 className={`text-4xl sm:text-5xl lg:text-6xl font-bold text-white space-y-2 transition-all duration-1000 ease-in-out ${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-x-4'}`}>
                  <span className="block text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-200">
                    CONNECT ALL
                  </span>
                  <span className="block text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-200">
                    YOUR LINKS IN
                  </span>
                  <span className="block text-transparent bg-clip-text bg-gradient-to-r from-primary/80 to-rose-500">
                    ONE PLACE
                  </span>
                </h1>
              </div>

              <p className={`text-gray-300 text-lg max-w-xl transition-all duration-1000 ${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4'}`}>
                Create modern, feature-rich bio pages and streamline your social
                presence with one single link for free.
              </p>

              {!user ? (
                <div className="flex flex-col sm:flex-row gap-4 max-w-md">
                  <div className="relative flex-grow">
                    <span className="absolute left-4 top-1/2 -translate-y-1/2 text-primary/80">
                      Phans.bio/
                    </span>
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="w-full px-4 py-3 pl-[6.5rem] rounded-lg bg-white/5 border border-white/10 text-white placeholder-gray-500 focus:outline-none focus:border-red-500 focus:ring-1 focus:ring-red-500"
                      placeholder="username"
                    />
                  </div>
                  <Link
                    to={`/signup?auto_claim=${username}`}
                    className="px-8 py-3 bg-gradient-to-r from-primary/80 to-secondary/80 text-white rounded-lg font-medium hover:opacity-90 transition-opacity whitespace-nowrap flex items-center gap-2"
                  >
                    Claim Now
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              ) : (
                <div className="flex flex-col sm:flex-row gap-4 max-w-md">
                  <Link
                    to="/dashboard"
                    className={`px-8 py-3 bg-gradient-to-r from-primary/80 to-secondary/80 text-white rounded-lg font-medium hover:opacity-90 hover:translate-x-4 hover:scale-105 whitespace-nowrap flex items-center gap-2 transition-all duration-1000 ${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4'}`}
                  >
                    Customize Now
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              )}

              <div className={`flex gap-6 text-gray-400 transition-all duration-1000 ${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4'}`}>
                {["twitter", "instagram", "tiktok", "youtube", "twitch"].map(
                  (platform, index) => (
                    <i
                      key={index}
                      className={`fab fa-${platform} text-2xl hover:text-white transition-colors cursor-pointer`}
                    ></i>
                  )
                )}
              </div>

              {user && <PremiumNotify user = {userDetails} />}
            </div>

            <div className={`relative h-[400px] sm:h-[500px] lg:h-[600px] w-full max-w-[400px] mx-auto transition-all duration-1000 ${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4'}`}>
              {["from-emerald-500 to-teal-500", "from-red-500 to-rose-500"].map(
                (gradient, index) => (
                  <div
                    key={index}
                    className={`absolute top-1/2 right-1/2 w-48 sm:w-56 lg:w-64 aspect-[1/1.6] transform ${
                      index === 0
                        ? "translate-x-[45%] translate-y-[-55%] rotate-6 z-10"
                        : "translate-x-[55%] translate-y-[-45%] -rotate-6"
                    }`}
                  >
                    <div
                      className={`w-full h-full bg-gradient-to-br ${gradient} rounded-2xl p-4 sm:p-6 shadow-xl`}
                    >
                      <div className="w-12 sm:w-16 h-12 sm:h-16 bg-white/20 rounded-full mb-4 flex items-center justify-center">
                        <i className="fas fa-user text-white/40 text-xl sm:text-2xl"></i>
                      </div>
                      <div className="space-y-3 sm:space-y-4">
                        <div className="h-3 sm:h-4 w-3/4 bg-white/20 rounded"></div>
                        <div className="h-3 sm:h-4 w-1/2 bg-white/20 rounded"></div>
                        <div className="grid grid-cols-3 gap-2 sm:gap-3 mt-6 sm:mt-8">
                          {[
                            "fa-link",
                            "fa-music",
                            "fa-video",
                            "fa-image",
                            "fa-store",
                            "fa-heart",
                          ].map((icon, i) => (
                            <div
                              key={i}
                              className="w-full aspect-square bg-white/20 rounded-lg flex items-center justify-center"
                            >
                              <i
                                className={`fas ${icon} text-white/40 text-sm sm:text-base`}
                              ></i>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>
      <PartnerSection />
      <Discord />
    </Layout>
  );
};

export default HomePage;

export const meta: MetaFunction = ({ params }) => {
  const Title = "Home";
  return [
    { title: Title + " - Phans.bio" || "Profile" },
    {
      name: "description",
      content: `Phans Development Home, Phans.bio.`,
    },
    {
      name: "twitter:description",
      content: `Phans Development Home, Phans.bio.`,
    },
    {
      property: "og:description",
      content: `Phans Development Home, Phans.bio.`,
    },
    {
      property: "og:url",
      content: "https://phans.bio" + "/",
    },
    {
      name: "twitter:url",
      content: "https://phans.bio" + "/" + "",
    },
    {
      name: "twitter:title",
      content: Title + " - Phans.bio" || "Profile",
    },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:image", content: "/meta.png" },
    { property: "og:image", content: "/meta.png" },
    {
      name: "theme-color",
      media: "(prefers-color-scheme: dark)",
      content: "red",
    },
    {
      name: "theme-color",
      media: "(prefers-color-scheme: light)",
      content: "blue",
    },
  ];
};
